module.exports = [{
      plugin: require('/home/runner/work/eyamenko.github.io/eyamenko.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/home/runner/work/eyamenko.github.io/eyamenko.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140627652-1","head":true},
    },{
      plugin: require('/home/runner/work/eyamenko.github.io/eyamenko.github.io/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coding Comrade: Living the Full-Stack Life","short_name":"Coding Comrade","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"static/image.jpg"},
    },{
      plugin: require('/home/runner/work/eyamenko.github.io/eyamenko.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/eyamenko.github.io/eyamenko.github.io/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/runner/work/eyamenko.github.io/eyamenko.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
